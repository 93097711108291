import { NgModule } from '@angular/core';

/* Components */
import { RightHeaderComponent } from './right-header/right-header.component';
import { SettingsMenuComponent } from './settings-menu/settings-menu.component';
import { StatusHeaderComponent } from './status-header/status-header.component';
import { ComponentsModule } from 'components/components.module';
import { CommonModule } from '@angular/common';
import { ConsoleMapModule } from 'map/console-map.module';
import { ChangeCredentialsModalComponent } from './change-credentials-modal/change-credentials-modal.component';
import { SafetyObservationDisclaimerComponent } from './safety-observation-disclaimer/safety-observation-disclaimer.component';

@NgModule({
    declarations: [
        RightHeaderComponent,
        SettingsMenuComponent,
        StatusHeaderComponent,
        ChangeCredentialsModalComponent,
        SafetyObservationDisclaimerComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        ConsoleMapModule,
    ],
    exports: [
        RightHeaderComponent,
        SettingsMenuComponent,
        StatusHeaderComponent,
        ChangeCredentialsModalComponent,
        ComponentsModule,
        ConsoleMapModule,
        SafetyObservationDisclaimerComponent,
    ],
})
export class SharedModule { }
